<template>
  <div class="bg">
    <div class="header">
      <div class="headerL">
        <router-link to="/">
          <img src="@/assets/image/logo.png" alt="logo" />
        </router-link>
      </div>
      <div class="title">外研教学评系统用户协议</div>
    </div>
    <div class="main">
      <p>版本发布日期：2023年7月24日</p>
      <p>
        <router-link to="/historyAgreement" target="_blank">
          【历史版本】
        </router-link>
      </p>
      <br />
      <p>
        尊敬的用户，欢迎您注册（含登录）成为外研教学评系统（以下统称“外研教学评”或“本产品”或“我们”）用户。外研教学评旨在为用户提供英语阅读教学支持、认证、反馈服务，包含外研教学评网站https://unilearn.fltrp.com以及相配套的悦测评、悦教大课堂、优诊学、iWay英语能力等级测试系统、外研拼读等产品。外研教学评的所有者和运营者为外语教学与研究出版社有限责任公司（下称“外研社”），本协议由您与外研社之间签订。
      </p>
      <br />
      <p>
        在使用外研教学评之前，您应当阅读并同意《外研教学评用户协议》（以下统称“本协议”）以及《外研教学评隐私政策》。请您务必仔细阅读、充分理解本协议各条款内容，特别是免除或者限制责任的条款，管辖与法律适用条款，以及关于开通或使用第三方提供的某项服务的相关条款（如有），我们将使用加粗及下划线的方式提请您注意。
      </p>
      <br />
      <p>
        您点击/勾选“同意”或“下一步”，或开始使用外研教学评服务，均视为您已阅读并同意签署本协议。本协议即在您与我们之间产生法律效力，成为对双方均具有约束力的法律文件。此后您不得以未阅读/未同意本协议内容或类似理由提出任何形式的抗辩。如您不同意本协议，请停止使用外研教学评相关服务。
      </p>
      <br />

      <p class="weight">
        特别提醒您，外研教学评是一款为提供英语能力测评的产品，因此我们的产品或服务会向未满十四周岁的未成年人开放，因此，如您是未满十四周岁的未成年人，请您在监护人陪同阅读本协议，以及我们为您专门制定的《儿童隐私保护声明》，并在获得您的监护人的同意之后，方可注册和使用外研教学评服务。
      </p>
      <br />
      <div class="h1">第1条 本用户协议的确认和接纳</div>
      <ul>
        <li>
          1.1
          外研教学评的各项电子服务的所有权和运作权归外研社所有。您需要同意本用户协议并完成注册程序或由您所在的学校向您分配账号，才能成为本产品的正式用户。您确认：本用户协议是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。
        </li>
        <li>
          1.2
          您点击同意本协议的，即视为您确认自己具有享受本产品服务、在线学习等相应的权利能力和行为能力，能够独立承担法律责任。
        </li>
        <li>
          1.3
          外研教学评保留在中华人民共和国大陆地区法施行之法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。
        </li>
        <li>
          1.4为给您提供更好的服务，我们会随着外研教学评业务的发展，适时修改和更新本协议，该修改将构成本协议的一部分。您可通过【打开网站-个人中心】查阅本协议的最新版本。
        </li>
        <li>
          1.5若对本协议作出重大变更，我们将会在修改生效前通过外研教学评或以其他适当的方式提醒您更新的内容。如您不同意该变更，可以选择停止使用我们的服务；如您继续使用我们的服务，即表示同意接受经修订的本协议的约束。
        </li>
      </ul>
      <div class="h1">第2条 我们的服务</div>
      <ul>
        <li>
          2.1
          外研教学评以“聚焦英语阅读、提高学生阅读能力”为目标，目前为用户提供了分年龄、分学段的英语阅读图书推荐、英语阅读等级测评、拼读能力反馈等等众多内容，您可根据自身情况进行选择。
        </li>
        <li>
          2.2我们将授予您一项个人的、不可转让的、非排他性的使用许可，您可基于非商业目的使用本产品。除另有声明或约定外，我们依法享有外研教学评的一切合法权益，并保留本协议未明确授予您的其他任何权利。
        </li>
        <li>
          2.3
          您必须自行准备如下设备和承担如下开支：（1）上网设备，包括并不限于计算机、移动终端或者其他上网终端、调制解调器及其他必备的上网装置；（2）上网开支，包括并不限于网络接入费、上网设备租用费、手机流量费等。
        </li>
      </ul>
      <div class="h1">第3条 用户注册和使用</div>
      <ul>
        <li>
          3.1
          您在使用本产品时，需要按照页面的提示提供您的手机号码完成账号注册。该账号是您使用我们产品或服务的唯一通行证，您可以通过该账号登录并使用外研教学评。如您是通过学校给予的账号注册/登录外研教学评的，则您所在的学校会提前将您的部分个人信息提供给我们，我们录入生成账号提供给您的学校，您的学校将账号提供给您，您凭学校给予的账号登录和使用本产品。
        </li>
        <li>
          3.2您应当诚信向我们提供注册/登录所需要的信息，您保证您所提供的信息真实、准确、完整、合法有效，您的注册资料如有变动的，应及时更新其注册资料。如果您提供的注册资料不合法、不真实、不准确而引起的相应责任及后果由您自行承担，并且外研教学评保留终止您使用外研教学评各项服务的权利。
        </li>
        <li class="weight">
          3.3
          我们特别提醒您，应妥善保管您的账号和密码。当您使用完毕后应当安全退出，以免发生包括账号密码失窃在内的不良后果。若因您的保管不善导致被盗号或其他损害您或任何第三方的合法权益的，一切责任由您自行承担。
        </li>
        <li>
          3.4
          您在使用外研教学评过程中，应避免透露各类财产账户、银行卡、信用卡、第三方支付账号及对应密码等重要资料，否则由此造成的损失由您自行承担。
        </li>
        <li>
          3.5您的账号只限您本人使用，未经我们许可，您不得以任何方式转让、出借或分享给他人使用。若您发现或怀疑您的账号密码已泄露，或您的账号存在被他人非法使用或有登录、使用异常情况的，请立即通知我们。否则，您账号下的一切使用行为将会被视为由您做出，您将自行承担所有由此导致的不利后果。
        </li>
        <li>
          3.6您应当保证，您通过本产品发布的任何内容符合法律法规（本协议中的“法律法规”指用户所属/所处地区（尤其包括中华人民共和国）、国家现行有效的法律、行政法规、司法解释、地方法规、地方规章、部门规章及其他规范性档以及对于该等法律法规的不时修改和补充，以及相关政策规定等，下同）、公序良俗、社会公德以及外研教学评的规则，且不会侵害任何主体的合法权益。
        </li>
        <li>
          3.7您在使用外研教学评服务时，您应当按照页面的提示准确完整地提供您的信息（包括但不限于您的姓名/昵称、手机号码），以便我们在必要时与您联系。您了解并同意：您有义务保证您提供的信息的真实性和有效性，否则所有因此导致的不利后果将由您自行承担。
        </li>
        <li>
          3.8
          如为履行法定义务或处理您与我们之间的纠纷，我们可能会对您的部分账号及个人信息进行证据保全，包括但不限于公证、见证等。
        </li>
        <li class="weight">
          3.9
          您理解，你注册的账号的所有权归外研社所有，您完成申请注册流程后，获得相应账号的使用权。账号使用权仅属于初始申请注册人，初始申请注册人不得赠与、借用、租用、转让或售卖账号或者以其他方式许可非初始申请注册人使用账号。如我们发现或者有合理理由认为使用者并非初始申请注册人，为保障账号安全，我们有权立即暂停、终止向该注册账号提供服务，或者注销该账号。
        </li>
      </ul>
      <div class="h1">第4条用户隐私制度</div>
      <ul>
        <li>
          4.1我们深知个人信息对您的重要性，尊重和保护用户个人信息和隐私是外研社的一项基本政策。我们会采取合理的措施保护您的个人信息与隐私。我们承诺，除非获得用户同意，本产品不会收集、使用其提供服务所必需以外的用户个人信息或者将信息用于提供服务之外的目的。
        </li>
        <li>
          4.2我们会尽最大努力运用各种相关安全技术和程序，建立完善的管理制度以保护您的个人信息及隐私安全，以免遭受未经授权的访问、使用或披露。
        </li>
        <li>
          4.3我们有权在遵守法律法规的前提下，以明示的方式获取、使用、储存和分享您的个人信息。我们不会在未经您授权时，公开、编辑或透露您的个人信息及您保存在本产品上的非公开内容。您同意并保证：本产品有权根据我们制定的个人信息保护规则收集使用您的相关信息。
        </li>
        <li>
          4.4对于您所提交的涉及个人信息的内容，我们将严格按照《外研教学评隐私政策》的规定进行收集、处理和使用。请您仔细阅读《外研教学评隐私政策》，以帮助您更好地保护您的个人信息。如您对此有任何疑问，欢迎您通过文末的联系方式，与我们联系。
        </li>
      </ul>
      <div class="h1">第5条未成年人使用条款</div>
      <ul>
        <li>
          5.1如您是未满14周岁的未成年人，请您通知您的监护人共同阅读并确认《儿童隐私保护声明》，并在您使用服务、提交个人信息之前，务必寻求他们的同意和指导。
        </li>
        <li>
          5.2未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，并应在取得监护人的同意以及在监护人指导下正确使用本产品。
        </li>
        <li>
          5.3未成年人用户在使用本产品时，应注意以下事项，提高安全意识，加强自我保护：<br />
          <p class="pl1">
            (a)认清网络世界与现实世界的区别，合理安排使用软件时间，有效利用本产品辅助日常学习，避免沉迷网络；
          </p>
          <p class="pl1">
            (b)填写个人资料时，加强个人保护意识，以免不良分子对个人生活造成骚扰；
          </p>
          <p class="pl1">(c)在监护人或老师的指导下，学习正确使用网络。</p>
          <p class="pl1">
            (d)监护人、学校均应对未成年人用户使用本产品的行为（含使用付费服务），多做监督和引导。
          </p>
        </li>
        <li>
          5.4本产品含有付费服务，未成年用户如需使用，请征得监护人同意并在监护人陪同下进行付费。
        </li>
        <li>
          5.5未成年用户理解，如因您违反法律法规或本协议内容，则您及您的监护人应依照法律规定以及本协议约定承担因此可能引起的全部法律责任。
        </li>
      </ul>
      <div class="h1">第6条依法言行义务</div>
      <ul>
        <li>
          6.1内容规范：您理解并同意，外研社一直致力于提供文明健康、规范有序的网络环境，您不得利用外研教学评制作、复制、发布、传播任何违法违规、有悖于社会主义价值观、侵犯任何第三方合法权益或干扰本产品正常运营的内容，包括但不限于：
          <p class="pl1 weight underL">
            A.发布、传送、传播、储存违反国家法律法规禁止的内容或为前述行为提供任何便利：<br />
            a）违反宪法确定的基本原则的；<br />
            b）危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一的；<br />
            c）损害国家荣誉和利益的；<br />
            d）煽动民族仇恨、民族歧视，破坏民族团结的；<br />
            e）破坏国家宗教政策、宣扬邪教和封建迷信的；<br />
            f）散布谣言、扰乱社会秩序、破坏社会稳定的；<br />
            g）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；<br />
            h）侮辱或者诽谤他人、侵害他人合法权益的；<br />
            i）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；<br />
            j）以非法民间组织名义活动的；<br />
            k）不符合《即时通信工具公众信息服务发展管理暂行规定》及遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等“七条底线”要求的；<br />
            l）含有法律、行政法规禁止的其他内容的。
          </p>
          <p class="pl1 underL">
            B.储存、传播侵害他人名誉权、肖像权、知识产权等合法权利的内容，涉及他人隐私、个人信息或资料的；
          </p>
          <p class="pl1">
            C.您所设置的账号名、昵称等信息不得违反国家法律法规，否则我们可对您的账号名、昵称进行暂停使用或注销等处理，并向主管机关报告。
          </p>
        </li>
        <li>
          6.2
          使用规范：除非法律允许或经我们书面许可，您使用本产品过程中不得从事下列行为（不论是否以营利为目的）：
          <p class="pl1 weight">
            (a)对本产品进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本产品的源代码；<br />
            (b)对外研教学评拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；<br />
            (c)对本产品运行过程中释放到任何终端内存中的数据、软件或服务运行过程中客户端与服务器端的交互数据，以及相关软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或未经外研社授权的第三方工具/服务接入本产品客户端软件和相关系统；<br />
            (d)通过修改成伪造外研教学评软件运行中的指令、数据，增加、删减、变动外研教学评软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；<br />
            (e)通过非外研社开发、授权的第三方软件、插件、外挂、系统，登录或使用外研教学评软件及服务，或制作、发布、传播上述工具；<br />
            (f)自行或者授权他人、第三方软件对外研教学评软件及其组件、模块、数据进行干扰；<br />
            (g)不得利用本产品从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；<br />
            (h)不得教唆他人从事本条所禁止的行为；<br />
            (i)不得利用在本产品注册的账户进行牟利性经营活动或其他任何牟利性或非牟利性商业活动，也不得将本产品作为从事商业活动的场所、平台或其他任何形式的媒介；<br />
            (j)其他末经外研社明示授权的行为。
          </p>
        </li>
        <li>
          6.3您认可并同意外研社保有删除产品内各类不符合法律政策或不真实的信息内容而无须通知您的权利。若您未遵守以上规定的，外研社有权作出独立判断并采取暂停或关闭您的账号等措施。您须对自己在网上的言论和行为承担法律责任。
        </li>
      </ul>
      <div class="h1">第7条 商品信息</div>
      <ul>
        <li>
          您理解并认可外研教学评上的产品、资源或服务的价格等商品信息随时都有可能发生变动，外研教学评不会就每次的变动做特别的通知。由于网站上产品、资源或服务信息的数量比较庞大，虽然外研教学评会尽最大努力保证您所浏览产品、资源或服务信息的准确性，但由于各种原因，外研教学评显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解；外研教学评欢迎纠错，并会视情况给予纠错者一定的奖励。
        </li>
        <li>为表述方便，产品、资源或服务简称为“商品”。</li>
      </ul>
      <div class="h1">第8条 所有权及知识产权协议</div>
      <ul>
        <li>
          8.1
          您一旦接受本协议，对于您提供、上传、创作、发布在外研教学评的文字、评论、图片、照片、视频、音频等任何形式的信息内容（包括但不限于评价、咨询、各类话题文章等），您免费授予外研社及其关联公司至保护期终止为止、不可撤销的、全球范围的、排他的许可使用著作权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可许可使用的权利）、肖像权、用户ID名称等相关的全部合法权利权益，就外研社具有诉讼主体资格的权利、权益，外研社有权以自身名义对第三方侵权行为取证及提起诉讼。您同意外研社及其关联公司有权存储、使用、复制、修订、编辑、发布、展示、翻译、分发、推广、出版、发行、信息网络传播您以上信息内容，有权改编制作派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内。外研社有权就上述权利再许可给第三方。
        </li>
        <li>
          8.2
          本协议已经构成《中华人民共和国著作权法》第二十六条（条文序号依照2020年版著作权法确定）及相关法律规定的著作财产权等权利许可使用书面协议，其效力及于用户在外研教学评网站上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。
        </li>
        <li>
          8.3
          您同意并已充分了解本协议，承诺不将已发表于外研教学评的信息，以任何形式发布或授权其他主体以任何方式使用（包括但不限于在各类网站、媒体上使用）。
        </li>
        <li>
          8.4
          外研社是外研教学评的制作者、所有者以及运营者,拥有此网站内容及资源的著作权等合法权利,受国家法律保护,有权不时地对本协议及本产品的任何内容进行修改，并在本产品张贴，无须另行通知用户。在法律允许的最大限度范围内，外研教学评对本协议及本站内容拥有解释权。
        </li>
        <li>
          8.5
          除法律另有强制性规定外，未经外研教学评明确的特别书面许可,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本站的信息内容，否则，外研教学评有权追究其法律责任。
        </li>
        <li>
          8.6
          外研教学评所刊登的资料信息（诸如文字、图表、标识、按钮图示、图像、声音档片段、数字下载、数据编辑和软件），均是外研社或其内容提供者的财产，受中国和国际版权法的保护。本产品上所有内容的汇编是外研社的排他财产，受中国和国际版权法的保护。本产品上所有软件都是外研社或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。
        </li>
      </ul>
      <div class="h1">第9条 责任限制及不承诺担保</div>
      <ul>
        <li>
          除非另有明确的书面说明,本产品及其所包含的或以其他方式通过本产品提供给您的全部信息、内容、材料、产品（包括软件）和服务，均是在"按现状"和"按现有"的基础上提供的。外研社有权于任何时间暂时或永久修改或终止本产品所提供的信息、内容、材料、产品（包括软件）和服务(或其任何部分)，而无论其通知与否，外研社对用户和其他任何第三人均无需承担任何责任。
        </li>
        <li>
          除非另有明确的书面说明,
          外研社不对本产品的运营及其包含在本产品上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。
        </li>
        <li>
          如因不可抗力或其他本产品无法控制的原因使本产品销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，外研社不承担任何责任，但外研社会合理地尽力协助处理善后事宜。
        </li>
        <li>
          外研社为达成您使用本产品之目的会在所需的最短期限内保留您的个人信息，但法律法规有规定的情形除外。如您注销您的账号，或我们提供的服务终止或取消，我们可以从服务器上永久删除您的数据，除非法律有明确规定除外，此种情况下，我们将无法向您返还或在提供任何数据。
        </li>
      </ul>
      <div class="h1">第10条 协议更新及用户关注义务</div>
      <ul>
        <li>
          根据国家法律法规变化及网站运营需要，我们有权对本协议不时地进行修改，修改后的协议将公布在本产品中，您可随时登录查阅最新协议；您有义务不时关注并阅读最新版的协议及网站公告。如您不同意更新后的协议，可以且应立即停止接受外研教学评依据本协议提供的服务；如您继续使用本产品提供的服务的，即视为同意更新后的协议。外研教学评建议您在使用本产品之前阅读本协议及本站的公告。
          如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余协议的有效性和可执行性。
        </li>
      </ul>
      <div class="h1">第11条 法律责任声明</div>
      <ul>
        <li>
          （1）因您违反本协议致使我们的合法权益遭受损失时，我们有权随时解除或终止本协议，并要求您赔偿我们的损失。（损失的范围包括但不限于直接损失、律师代理费、诉讼费、保全费、差旅费及其他实现债权所需要的费用。
        </li>
        <li>
          （2）因您违反本协议或法律法规而导致我们或任何第三方合法权益遭受损失时，您应对此承担法律责任（包括但不限于直接损失、间接损失）。若我们因此遭受任何第三方索赔或行政处罚，有向您追索赔偿相应损失的权利，并追究您的违约责任。
        </li>
        <li>
          （3）因可归责于我们的原因直接导致您的合法财产权益受损的，我们将在您购买本产品服务所支付的价款之范围内，对您的损失承担赔偿责任。
        </li>
        <li>
          （4）对于因您滥用、错误使用或未经授权修改外研教学评所引起的软件使用问题，我们不承担任何责任。
        </li>
      </ul>
      <div class="h1">第12条服务的终止及中断</div>
      <ul>
        <li>
          （1）如果我们发现、收到他人举报或投诉您违反本协议约定或违反法律法规和国家有关规定的，我们有权采取警示、冻结、终止/中止/限制使用外研教学评账号等功能、暂停或终止我们的部分或所有服务、解除或终止本协议，或提出损害赔偿等措施,这些可能的情况包括但不限于以下情況：
          <p class="weight pl1 underL">
            (a)如我们合理相信您已违反本协议；<br />
            (b)如您使用外研教学评服务对我们或我们的其他用户造成风险、可能导致我们面临第三方提出索赔的威胁，或可能影响我们的声誉。
          </p>
        </li>
        <li>
          （2）在终止本协议后，我们会根据本协议及我们的《外研教学评隐私政策》的规定处理您的个人信息。如我们暂停或终止服务，或我们终止您登录或使用我们的服务，除法律有明确规定外，我们无义务向您或您指定的第三方披露您账户中的任何信息。本协议终止后，我们仍享有下列权利：根据法律规定，继续保存您留存于本产品的的各类信息；对于您过往的违约行为，本产品仍可依据本协议向您追究违约责任。同时，我们将会在服务终止后的合理期限内永久删除您的相关信息且无需通知您。因此，我们建议您定期对您的信息进行备份。
        </li>
        <li>
          您理解并同意，我们的所有服务和软件均是按原状提供。我们将尽最大努力向您提供服务，确保服务的连贯性和安全性。<span
            class="weight"
          >
            在适用法律允许的最大范围内，我们明确表示不会对外研教学评或通过外研教学评提交、提供或显示的内容做出任何声明、保证或承诺，包括但不限于：
          </span>
          <p class="weight pl1 underL">
            (a)我们的服务或软件将不受干扰、是安全的、没有错漏或没有病毒；<br />
            (b)我们的服务或软件将会与您的设备兼容；<br />
            (c)我们的服务或软件有关适销质量、适合特定用途或没有侵犯任何人的知识产权等方面的任何人的合法权利。
          </p>
        </li>
        <li>
          （4）您理解并同意，在使用本产品的过程中，可能会遇到不可抗力等风险因素，使服务中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，我们将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失我们在法律允许的范围内免责。
        </li>
        <li>
          在法律允许的范围内，我们对以下情形导致的服务中断或受阻不承担责任，但我们会尽最大努力降低相关损失：<span
            class="weight underL"
          >
            受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；用户或我们的电脑软件、系统、硬件和通信线路出现故障；用户操作不当；用户通过非外研社授权的方式使用本产品；其他外研社无法控制或合理预见的情形。
          </span>
        </li>
        <li class="weight underL">
          （6）您理解并同意，在使用本产品的过程中，可能会遇到网络信息或其他用户行为带来的风险，在法律允许的最大范围内，我们不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。
        </li>
      </ul>
      <div class="h1">第13条 服务费用</div>
      <ul class="underL">
        <li class="weight">
          （1）我们的部分服务是以收费方式提供的，你可以通过付费方式购买收费服务。对于收费服务，我们会在您使用之前给与明确的提示，只有根据提示确认同意并完成支付，才能使用该等收费服务。支付行为的完成以银行或第三方支付平台生成“支付已完成”的确认通知为准。用户支付完成后，该项服务即时生效。
        </li>
        <li>
          （2）我们可能根据实际需要对收费服务的收费标准、方式进行修改和变更，我们也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，我们将在相应服务页面进行通知或公告。如果您不同意上述修改、变更或付费内容，则可以停止使用该服务。
        </li>
        <li>
          （3）在我们降低收费服务的收费标准或者将收费服务改为免费服务提供时，我们保留不对原付费用户提供退费或者费用调整之权利。
        </li>
        <li>
          （4）您理解并同意：我们部分付费服务有使用时间限制，付费成功后，您享有一定期限的使用权(具体使用时间以支付页面为准)，请您在规定时间内及时开展学习活动。如果因为您的原因，导致服务已过有效期，对您已支付的费用我们将不作退回或退还。
        </li>
        <li>
          （5）如您因违反本协议或法律法规的规定而被暂停/终止部分或全部服务，被限制/中止账号的登录和使用，对您已支付的费用，我们将不作退回或退还。
        </li>
        <li>
          （6）您如果对付费购买的服务有疑问或不满意，可以根据文末提供的联系方式与我们进行沟通。
        </li>
        <li>
          （7）您理解并同意：为了向您提供服务，外研教学评相关服务会使用您的台式电脑、笔记本电脑或移动通讯终端的处理器和带宽以及相关资源。本产品使用过程中可能产生数据流量的相关资费，您需自行向运营商了解相关资费信息，并自行承担相关费用。
        </li>
      </ul>
      <div class="h1">第14条适用法律及争议解决</div>
      <ul>
        <li>
          本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。如发生本协议与适用之法律相抵触时，则这些协议将完全按法律规定重新解释，而其他有效协议继续有效。
        </li>
        <li>
          如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向外研社所在地有管辖权的人民法院提起诉讼。
        </li>
      </ul>
      <div class="h1">第15条 其他</div>
      <ul>
        <li>
          （1）外研教学评尊重用户的合法权利，本协议及本网站上发布的各类规则、声明等其他内容，均是为了更好地、更加便利地为用户提供服务。本站欢迎用户和社会各界提出意见和建议，外研教学评将虚心接受并适时修改本协议及本站上的各类规则。
        </li>
        <li>
          （2）我们未行使或执行本协议的任何权利或规定，并不构成对前述权利或规定之放弃。
        </li>
        <li>
          （3）如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
        </li>
        <li>（4）我们保留对外研教学评涉及的所有产品说明性文档的解释权。</li>
        <li>
          （5）由于互联网行业高速发展，您与我们签署的本协议列明的条款并不能完整罗列并覆盖您与我们的所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，我们有权利为本协议制定补充协议，与本协议不可分割且具有同等法律效力。如您同意继续使用本产品，视为您同意上述补充协议。
        </li>
      </ul>
      <div class="h1">第16条 联系我们</div>
      <div>
        如您对本协议或对外研教学评服务相关事宜有任何问题、意见或建议，您可通过在线客服与我们联系；<br />
        公司名称：外语教学与研究出版社有限责任公司 <br />
        联系地址：北京市西三环北路19号外研社大厦研发中心 <br />
        联系电话：010-88819743<br />
        联系邮箱：yanfa@fltrp.com<br />
        （悦测评、外研拼读、悦教大讲堂）邮箱：wangjinmeng@fltrp.com<br />
        （悦测评、外研拼读、悦教大讲堂）电话：010-88819188<br />
      </div>
    </div>
  </div>
</template>
<style scoped>
.header {
  background-color: #47475c;
  color: #ffffff;
  overflow: hidden;
}
.headerL {
  float: left;
  color: #ffffff;
  overflow: hidden;
}
.headerL img {
  height: 3.2rem;
  padding: 0.3rem;
  margin-left: 0.5rem;
}
.title {
  font-size: 2rem;
  text-align: center;
  line-height: 60px;
  width: 800px;
  margin: 0 auto;
}
.bg {
  background-color: #eee;
}
.main {
  padding: 1.786em 2.5em;
  background-color: #fff;
  max-width: 1000px;
  margin: 2rem auto;
  line-height: 2.2em;
  margin-bottom: 0;
}
.h1 {
  font-weight: 500;
  font-size: 1.5rem;
  padding: 1.5rem 0;
}
.weight {
  font-weight: 600;
}
ul {
  list-style-type: disc;
  padding-left: 3rem;
}
.pl1 {
  padding-left: 1rem;
}
.underL {
  text-decoration: underline;
}
</style>
